<template>
	<div>
		<v-dialog v-model="dialogProp" width="800" persistent>
			<v-card>
				<h3>{{ $t('operations.infomessages.acceptInfoMessage') }}</h3>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="$emit('closeDialog')">{{ $t('pui9.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'OperationsToolbarInfo',
	props: {
		dialogProp: {
			type: Boolean,
			required: true
		}
	},
	methods: {}
};
</script>
